import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneNumberInput = ({
  label,
  value,
  onChange,
  touched,
  error,
  errorMessage,
  disabled,
}) => {
  // console.log("🚀 ~ disabled:", disabled)
  const handlePhoneChange = (value, country, e, formattedValue) => {
    // console.log("🚀 ~ handlePhoneChange ~ formattedValue:", formattedValue)
    onChange(formattedValue);
  };

  const [isFocus, setIsFocus] = useState(false)

  return (
    <div className={`font-slussen ${disabled ? "input-disabled" : ""}`}>
      {/* <label
        className={`text-[12px] ${error ? "text-accentRed" : "text-dark"} `}
      >
        {label}
      </label> */}
      <div className={` `} >
        <PhoneInput
          className={`w-full border border-[#003A7B] ${error||touched ? "border border-accentRed" : ""
            } font-slussen  rounded-md  h-11 overflow-x-hidden flex flex-wrap content-center `}
          placeholder="Phone Number"
          country={"in"}
          prefix={"+"}
          enableSearch={true}
          value={value}
          onChange={handlePhoneChange}
          disabled={disabled}
          disableDropdown={true}
          inputStyle={{
            border: "2px",
            backgroundColor:`${disabled?"rgb(243 244 246) ":""}`,
          }}
          buttonStyle={{
            backgroundColor: "white",
            border: "2px solid white",
            marginLeft: "2px",
            backgroundColor:`${disabled?"rgb(243 244 246) ":"white"}`,
            border:`${disabled?"rgb(243 244 246) ":"white"}`,
          }}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          containerStyle={{
            border: `${isFocus ? "2px solid #003A7B" : ""}`,
          }}
        />
      </div>

      {error||touched && (
        <span className="text-accentRed font-bold mt-8 text-[12px]">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default PhoneNumberInput;

