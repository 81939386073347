import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

import { Button } from "../ui";
import { setSelectedLanguage } from "store/language/languageSlice";
import useAuth from "utils/hooks/useAuth";
import LanguageSelect from "components/ui/languageSelect/languageSelect";
import { Avatar, Dropdown } from "components/ui";
import {
  webNavigationLinks,
  languageOptions,
} from "utils/options/headerOptions";

import user from "../../assets/images/user.png"
import logo from "assets/svg/wellness-help-logo.svg";
import { BurgerSvg } from "assets/svg";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FiActivity } from "react-icons/fi";
import { IoNewspaperOutline } from "react-icons/io5";
import { HiOutlineLogout, HiOutlineUser } from "react-icons/hi";

// import { current } from "@reduxjs/toolkit";

const Navigation = ({ className }) => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(
    (state) => state?.language?.language?.selectedLanguage
  );
  // console.log("🚀 ~ Navigation ~ selectedLanguage:", selectedLanguage)
  const { profile_photo, client_full_name, client_email } = useSelector(
    (state) => state?.auth?.user?.userData
  );
  const { signOut, authenticated } = useAuth();
  // console.log("🚀 ~ Navigation ~ authenticated:", authenticated)

  const navigate = useNavigate();
  const isTabletScreen = useMediaQuery({ minWidth: 768 });
  // console.log("🚀 ~ Navigation ~ isTabletScreen:", isTabletScreen)

  const [activeLink, setActiveLink] = useState("home");

  const [activeMobileLink, setActiveMobileLink] = useState("home");
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(selectedLanguage);
  // console.log("🚀 ~ Navigation ~ selectedLang:", selectedLang)

  const handleLanguageSelect = (language) => {
    setSelectedLang(language);
    dispatch(setSelectedLanguage(language));
  };

  const handleMobileLinkClick = (link) => {
    setActiveMobileLink(link);
    setDropdownOpen(false);
    setIsOpen(false);
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const dropdownItemList = [
    {
      _id: "1",
      label: {
        enLabel: "Profile information",
        gujLabel: "પ્રોફાઇલ માહિતી",
      },
      path: "/profile-details",
      icon: <HiOutlineUser />,
    },
    {
      _id: "2",
      label: {
        enLabel: "Report history",
        gujLabel: "રીપોર્ટ ઇતિહાસ",
      },
      path: "/profile-details",
      icon: <FiActivity />,
    },
    {
      _id: "3",
      label: {
        enLabel: "Payment history",
        gujLabel: "ચુકવણી ઇતિહાસ",
      },
      path: "/profile-details",
      icon: <IoNewspaperOutline />,
    },
  ];

  const UserAvatar = (
    <div className={classNames(className, "flex items-center gap-2")}>
      {/* <Avatar size={32} shape="circle" src={profile_photo} alt="profile" /> */}
      <Avatar size={32} shape="circle" src={profile_photo ? profile_photo : user} alt="profile" />
      <div className="hidden md:block">
        <div className="font-bold">{client_full_name}</div>
      </div>
    </div>
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="bg-[#D6E9FF] border-y-[1px] border-[#003A7B]  sticky top-0 z-20 lg:bg-[#F5F5F5] lg:border-y-white ">
      <div className="container font-syne">
        <div className="flex justify-between items-center">
          <div className="lg:w-full lg:flex lg:justify-between lg:items-center">
            {/* logo  */}
            <div className="hidden lg:block">
              <Link to="/">
                <img
                  className="w-[76px] h-[60px] my-[10px]"
                  src={logo}
                  alt=""
                />
              </Link>
            </div>
            <div className="block lg:hidden">
              <img
                className="w-[76px] h-[60px] my-[10px]"
                src={logo}
                alt=""
                onClick={
                  () => {
                    handleMobileLinkClick("home");
                    setIsOpen(false);
                    navigate( "/" );
                  }
                }
              />
            </div>
            {/* navigation links  */}
            <div className="hidden lg:flex lg:justify-around lg:gap-5 xl:gap-10">
              {webNavigationLinks?.map((link) => (
                <NavLink
                  key={link?.value}
                  to={link?.path}
                  className={({ isActive }) =>
                    `text-[#003A7B] font-medium ${isActive
                      ? " overflow-hidden relative after:bg-blue-500 after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-1/2"
                      : ""
                    }`
                  }
                >
                  {selectedLanguage?.code === "EN"
                    ? link?.label
                    : link?.gujLabel}
                </NavLink>
              ))}
            </div>

            <div className="hidden lg:flex lg:justify-end lg:items-center lg:gap-5">
              {/* auth module navigation  */}
              <div
                className={`${authenticated === true || authenticated !== ""
                    ? "hidden"
                    : "lg:flex"
                  } lg:justify-start lg:items-center lg:gap-2`}
              >
                <div>
                  <NavLink to="auth/sign-up">
                    <Button className="">
                      {selectedLanguage?.code === "EN" ? `Sign up` : `સાઇન અપ`}
                    </Button>
                  </NavLink>
                </div>
                <div>
                  <NavLink to="auth/sign-in">
                    <Button variant="solid" className="">
                      {selectedLanguage?.code === "EN" ? `LogIn` : `લોગઇન`}
                    </Button>
                  </NavLink>
                </div>
              </div>

              {/* language selector   */}
              <div className="lg:flex lg:justify-end lg:items-center ">
                <span className="cursor-pointer">
                  <LanguageSelect />
                </span>
              </div>

              {/* Profile toggler for authenticated user  */}
              <div
                className={`${authenticated === true || authenticated !== ""
                    ? "block cursor-pointer"
                    : "hidden"
                  }`}
              >
                <Dropdown
                  menuStyle={{ minWidth: 0 }}
                  renderTitle={UserAvatar}
                  placement="middle-center"
                >
                  <Dropdown.Item variant="header" className="">
                    <div className="pt-2 pb-4 px-3 flex items-center gap-2">
                      <Avatar
                        shape="circle"
                        src={profile_photo ? profile_photo : user}
                        alt="profile"
                      />
                      <div>
                        <div className="font-bold ">{client_full_name}</div>
                        <div className="text-xs">{client_email}</div>
                      </div>
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item variant="divider" />
                  {dropdownItemList?.map((item) => {
                    // console.log("🚀 ~ {dropdownItemList.map ~ item:", item)
                    return (
                      <Dropdown.Item
                        eventKey={
                          selectedLanguage?.code === "EN"
                            ? item?.label?.enLabel
                            : item.label?.gujLabel
                        }
                        key={item._id}
                        className="mb-1"
                      >
                        <div
                          className="cursor-pointer flex gap-2 items-center"
                          onClick={() =>
                            navigate(item.path, {
                              state: { selectedTab: item?.label },
                            })
                          }
                        >
                          <span className="text-xl opacity-50">
                            {item.icon}
                          </span>
                          <span>
                            {selectedLanguage?.code === "EN"
                              ? item?.label?.enLabel
                              : item.label?.gujLabel}
                          </span>
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                  <Dropdown.Item variant="divider" />
                  <Dropdown.Item
                    onClick={signOut}
                    eventKey="Sign Out"
                    className="gap-2"
                  >
                    <div
                      onClick={() => signOut()}
                      variant=""
                      className="cursor-pointer flex gap-2"
                    >
                      <span className="text-xl opacity-50">
                        <HiOutlineLogout />
                      </span>
                      <span>
                        {selectedLanguage?.code === "EN"
                          ? "Sign Out"
                          : "સાઇન આઉટ"}
                      </span>
                    </div>
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </div>
          </div>

          {/* Render mobile navigation */}
          <div className="lg:hidden">
            <BurgerSvg onClick={toggleMenu} />
            {isOpen && (
              <div className="fixed top-0 right-0 sm:w-1/2 w-full h-full bg-white z-40 shadow-lg overflow-scroll">
                <nav className="flex flex-col items-start ">
                  <div className=" border-b-[1px] border-[#003A7B] w-full py-6">
                    <div className="flex items-center text-center gap-24">
                      <svg
                        width="50px"
                        height="25px"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="pl-[16px]"
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        <path
                          d="M2.18131 5.67817L5.12734 8.8897C5.36542 9.17034 5.33092 9.59087 5.05027 9.82895C4.76962 10.067 4.3491 10.0325 4.11102 9.75189L0.193359 5.48117C0.137024 5.42457 0.0913745 5.35826 0.0586068 5.28543C0.0178584 5.19491 -0.00210677 5.09641 0.000175844 4.99716L0.00018562 4.99655C0.00245399 4.89752 0.0267135 4.80023 0.071208 4.71173C0.0755347 4.70316 0.0800457 4.69469 0.0847379 4.68631C0.11189 4.63773 0.145072 4.59278 0.183495 4.55251L4.20532 0.213391C4.4555 -0.0565336 4.87713 -0.0725271 5.14705 0.17765C5.41696 0.427826 5.43296 0.849464 5.18279 1.11938L2.19266 4.34541H9.33361C9.70165 4.34541 10 4.64376 10 5.0118C10 5.37983 9.70165 5.67818 9.33361 5.67818H2.18131V5.67817Z"
                          fill="#003A7B"
                        />
                      </svg>
                      <span className="font-syne text-center font-bold text-2xl text-[#003A7B]">
                        {selectedLanguage?.code === "EN" ? "Menu" : "મેનુ"}
                      </span>
                    </div>
                  </div>

                  {authenticated && (
                    <>
                      <div className="w-full flex justify-between items-center  py-[25px] ">
                        <div className="pt-2 pb-4 px-3 flex items-center gap-2 ">
                          <Avatar
                            size={64}
                            shape="circle"
                            src={profile_photo}
                            alt="profile"
                          />
                          <div className="">
                            <div className="font-bold text-[#003A7B]">
                              {client_full_name}
                            </div>
                            <div className="text-xs text-[#003A7B]">
                              {client_email}
                            </div>
                          </div>
                        </div>
                        <div
                          className="sm:pt-[0px] pt-[15px] px-4"
                          onClick={() => {
                            navigate(`/profile-details`, {
                              state: {
                                selectedTab: {
                                  enLabel: "Profile information",
                                  gujLabel: "પ્રોફાઇલ માહિતી",
                                }
                              },
                            });
                            setIsOpen(false);
                            setDropdownOpen(false);
                            handleMobileLinkClick("");
                          }}
                        >
                          {isTabletScreen ? (
                            <>
                              <Button size="md" className="">
                                {selectedLanguage?.code === "EN"
                                  ? "View"
                                  : "વધુ જુઓ"}
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button size="xs" className="">
                                {selectedLanguage?.code === "EN"
                                  ? "View"
                                  : "વધુ જુઓ"}
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {webNavigationLinks?.map((link) => {
                    // console.log("🚀 ~ {webNavigationLinks?.map ~ link:", link)
                    return (
                      <MobileNavLink
                        key={link.value}
                        isActive={activeMobileLink === link.value}
                        onClick={() => {
                          handleMobileLinkClick(link?.value);
                          setIsOpen(false);
                          navigate(link?.path);
                        }}
                      >
                        <span className="text-[#003A7B] font-medium font-syne hover:bg-[#DFEEFF] w-full py-[10px] pl-[16px] flex text-[18px]">
                          {selectedLanguage?.code === "EN"
                            ? link?.label
                            : link?.gujLabel}
                        </span>
                      </MobileNavLink>
                    )
                  })}

                  <div
                    className="w-full flex justify-between items-center "
                    onClick={() => {
                      toggleDropdown();
                    }}
                  >
                    <div>
                      <p className="text-[#003A7B] font-medium font-syne w-full py-[10px] pl-[16px] flex text-[18px]">
                        {selectedLanguage?.code === "EN" ? "Language" : "ભાષા"}
                      </p>
                    </div>
                    <div className="pr-4 text-[#003A7B]">
                      {dropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                  </div>

                  {dropdownOpen ? (
                    <>
                      <div className="w-11/12 mx-auto items-center ">
                        {languageOptions?.languages?.map((language) => {
                          return (
                            <>
                              <div className="relative" key={language?.name}>
                                <button
                                  className={`px-4 py-2 rounded-md focus:outline-none ${selectedLanguage?.code === language?.code
                                      ? "bg-[#DFEEFF] w-full"
                                      : ""
                                    }`}
                                  onClick={() => {
                                    // console.log("language", language)
                                    handleLanguageSelect(language);
                                    setIsOpen(false);
                                    setDropdownOpen(false);
                                  }}
                                >
                                  <div className="flex gap-4">
                                    {language?.icon}
                                    <span className="text-[#003A7B]">
                                      {selectedLanguage?.code === "EN"
                                        ? language?.name
                                        : language?.gujName}
                                    </span>
                                  </div>
                                </button>
                                {selectedLanguage?.code === language?.code && (
                                  <span className="absolute right-0 top-1/2 text-[#003A7B] transform -translate-y-1/2 mr-2">
                                    ✓
                                  </span>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div>
                    <div className="flex items-center">
                      {authenticated ? (
                        <>
                          <div className="pt-6 px-4 font-syne text-lg font-medium rounded-md">
                            <Button
                              icon={<HiOutlineLogout />}
                              onClick={() => signOut()}
                            >
                              {selectedLanguage?.code === "EN"
                                ? `Sign Out`
                                : `સાઇન આઉટ`}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={`flex justify-start items-center gap-2 pt-6 px-4`}
                          >
                            <div>
                              <NavLink to="auth/sign-up">
                                <Button className="">
                                  {selectedLanguage?.code === "EN"
                                    ? `Sign up`
                                    : `સાઇન અપ`}
                                </Button>
                              </NavLink>
                            </div>
                            <div>
                              <NavLink to="auth/sign-in">
                                <Button variant="solid" className="">
                                  {selectedLanguage?.code === "EN"
                                    ? `LogIn`
                                    : `લોગઇન`}
                                </Button>
                              </NavLink>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </nav>
              </div>
            )}
            {isOpen && (
              <div
                className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-30"
                onClick={toggleMenu}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileNavLink = ({ isActive, onClick, children }) => {
  return (
    <button
      className={`text-gray-600 w-full relative overflow-hidden ${isActive ? "bg-[#DFEEFF]" : ""
        }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Navigation;
